import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.css';
import Logo from './assests/Logo.png';
const Footer = () => {
    const navigate = useNavigate();

    const handleGetDemo = () => {
        window.open('https://meetings.hubspot.com/hutton130', '_blank');
    };
    return (
        <>
            <nav className={styles.nav}>
                <div>
                    <img onClick={() => navigate('/')} src={Logo} alt="LOGO" height={48} width={121} />
                </div>
                <div style={{ height: 'fit-content' }} className="d-flex align-items-center ">
                    <span onClick={() => navigate('/resources')} className={styles.navItem}>
                        Resources
                    </span>
                    <span onClick={() => window.open('https://blog.lensapp.ai')} className={styles.navItem}>
                        Blog
                    </span>
                    <span onClick={() => navigate('/pricing')} className={styles.navItem}>
                        Pricing
                    </span>
                    <span className={styles.navItem} onClick={() => navigate('/contact')}>
                        Contact Sales
                    </span>
                    <button className={styles.demoBtn} onClick={handleGetDemo}>
                        Get a Demo
                    </button>
                    <div className={styles.menuBtn}>
                        <p onClick={() => window.open('https://blog.lensapp.ai')} className={styles.menuItem}>
                            Resources
                        </p>
                        <p onClick={() => window.open('https://blog.lensapp.ai')} className={styles.menuItem}>
                            Blog
                        </p>
                        <p onClick={() => navigate('/pricing')} className={styles.menuItem}>
                            Pricing
                        </p>
                        <p className={styles.menuItem} onClick={() => navigate('/contact')}>
                            Contact Sales
                        </p>
                        <button className={styles.demoBtnFooter} onClick={handleGetDemo}>
                            Get a Demo
                        </button>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Footer;
