import styles from './index.module.css';
import Marquee from 'react-fast-marquee';
import laptopImg from './assests/laptop.png';
import laptopResponsiveImg from './assests/laptopResponsive.png';
import personOne from './assests/person1.png';
import section3BG from './assests/section3BG.png';
import section4Img from './assests/section4Icons.png';
import personTwo from './assests/person2.png';
import roadMapImg from './assests/roadmap.png';
import teamImg from './assests/team.png';
import techImg from './assests/tech.png';
import scaleImg from './assests/scale.png';
import operationsImg from './assests/operations.png';
import warnImg from './assests/warn.png';
import cybersecurityImg from './assests/cybersecurity.png';
import personThree from './assests/person3.png';
import comLogo1 from './assests/comLogo1.png';
import comLogo2 from './assests/comLogo2.png';
import comLogo3 from './assests/comLogo3.png';
import comLogo4 from './assests/comLogo4.png';
import comLogo5 from './assests/comLogo5.png';
import comLogo6 from './assests/comLogo6.png';
import section10BG from './assests/section10BG.png';
import { BsPlayCircle } from 'react-icons/bs';
import Modal from 'react-bootstrap/Modal';
import React, { useEffect } from 'react';
import Layout from '../../Components/Layout';
import { useLocation } from 'react-router-dom';
const Home = () => {
    const handleNavigateToToolkit = () => {
        window.open('https://toolkit.lensapp.ai/', '_blank');
    };
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleGetDemo = () => {
        window.open('https://meetings.hubspot.com/hutton130', '_blank');
    };
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <Layout>
            <div className="">
                <div className={styles?.sectionOne}>
                    <div className="row m-0 p-0 align-items-center">
                        <div className="col-sm-12 col-md-6 m-0 p-0 ">
                            {' '}
                            <div className={styles.sectionOneLeft}>
                                <h2>
                                    Lens. <span className={styles.gray6}>The Investment Readiness Platform.</span>
                                </h2>
                                <p>Helping Founders prepare for Investment to Maximise Business Value.</p>
                                <div className="d-md-flex">
                                    <button onClick={handleGetDemo} className={styles.fullSizeBtn}>
                                        Get a Demo
                                    </button>
                                    <div>
                                        {' '}
                                        <button className={styles.readyBtn + ' ms-md-4 ' + styles.fullSizeBtn} onClick={handleNavigateToToolkit}>
                                            How ready are you?
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 m-0 p-0">
                            <img src={screenWidth > 768 ? laptopImg : laptopResponsiveImg} className={styles.laptopImg} alt="laptop" />
                        </div>
                    </div>
                </div>
                <div className={styles.sectionTwo}>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-3">
                            <div className={styles.sectionTwoCard}>
                                <h1>Identify Business Gaps</h1>
                                <div className="position-absolute top-0 end-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="160" height="131" viewBox="0 0 160 131" fill="none">
                                        <g opacity="0.1">
                                            <circle cx="100" cy="31" r="90.4762" stroke="white" strokeWidth="19.0476" />
                                            <circle cx="99.9999" cy="30.9994" r="57.1429" stroke="white" strokeWidth="19.0476" />
                                            <path d="M125 31C125 44.8071 113.807 56 100 56" stroke="white" strokeWidth="19.0476" strokeLinecap="round" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3">
                            <div className={styles.sectionTwoCard}>
                                <h1>Remediate Weaknesses</h1>
                                <div className="position-absolute top-0 end-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="160" height="131" viewBox="0 0 160 131" fill="none">
                                        <g opacity="0.1">
                                            <circle cx="100" cy="31" r="90.4762" stroke="white" strokeWidth="19.0476" />
                                            <circle cx="99.9999" cy="30.9994" r="57.1429" stroke="white" strokeWidth="19.0476" />
                                            <path d="M125 31C125 44.8071 113.807 56 100 56" stroke="white" strokeWidth="19.0476" strokeLinecap="round" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3">
                            <div className={styles.sectionTwoCard}>
                                <h1>Maximise Business Value</h1>
                                <div className="position-absolute top-0 end-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="160" height="131" viewBox="0 0 160 131" fill="none">
                                        <g opacity="0.1">
                                            <circle cx="100" cy="31" r="90.4762" stroke="white" strokeWidth="19.0476" />
                                            <circle cx="99.9999" cy="30.9994" r="57.1429" stroke="white" strokeWidth="19.0476" />
                                            <path d="M125 31C125 44.8071 113.807 56 100 56" stroke="white" strokeWidth="19.0476" strokeLinecap="round" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3">
                            <div className={styles.sectionTwoCard}>
                                <h1>Secure Your Future</h1>
                                <div className="position-absolute top-0 end-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="160" height="131" viewBox="0 0 160 131" fill="none">
                                        <g opacity="0.1">
                                            <circle cx="100" cy="31" r="90.4762" stroke="white" strokeWidth="19.0476" />
                                            <circle cx="99.9999" cy="30.9994" r="57.1429" stroke="white" strokeWidth="19.0476" />
                                            <path d="M125 31C125 44.8071 113.807 56 100 56" stroke="white" strokeWidth="19.0476" strokeLinecap="round" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles?.sectionThree}>
                    <div className="row m-0 p-0">
                        <div className="col-sm-12 col-md-6 m-0 p-0">
                            <img src={section3BG} className={styles.sectionThreeImage} alt="section3BG" />
                        </div>
                        <div className="col-sm-12 col-md-6 m-0 p-0">
                            <div className={styles.sectionThreeContent}>
                                <div>
                                    <h3>Assess Your Firm’s Position</h3>
                                    <p>Learn where your firm stands and areas to focus on, before you present your most valuable asset to investors and buyers.</p>
                                    <button className={styles.fullSizeBtn} onClick={handleNavigateToToolkit}>
                                        Discover your Investment-Ready Score
                                    </button>
                                </div>{' '}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles?.sectionFour}>
                    <div className={styles.blueSection}>
                        <h3>Founders shouldn't be penalised for issues they can easily fix themselves</h3>
                        <VideoComponent />
                    </div>
                    <img src={section4Img} alt="icons" className="position-absolute top-0 end-0" />
                </div>
                <div className={styles.sectionFive}>
                    <div className={styles.testimonial}>
                        <span>Testimonial</span>
                        <p>
                            The Lens team was hugely influential in helping Fluid get funding. Highly recommend anyone looking to review their own tech investment or prepping for
                            investment rounds.
                        </p>
                        <div className={styles.testimonialByBody}>
                            <img src={personOne} alt="person" />
                            <div>
                                <p>Dan Justin</p>
                                <p> CTO Fluid</p>
                                <p> Business Solutions</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.sectionSix}>
                    <div className={styles.sectionSixTop}>
                        <h2>Unsure what you need to prepare and present?</h2>
                        <p>
                            Many founders enter the diligence process unprepared, leading to overwhelm as they rush to ready themselves for investors. Neglecting these common, yet
                            crucial issues can not only harm your reputation but also potentially reduce your exit value.
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-4 ">
                            <div className={styles.whiteCard}>
                                <span>Strategy & Roadmap</span>
                                <img src={roadMapImg} alt="RoadMap" />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 ">
                            <div className={styles.whiteCard}>
                                <span>Team</span>
                                <img src={teamImg} alt="RoadMap" />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 ">
                            <div className={styles.whiteCard}>
                                <span>Technology & Architecture</span>
                                <img src={techImg} alt="RoadMap" />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 ">
                            <div className={styles.whiteCard}>
                                <span>Scale & Resilience</span>
                                <img src={scaleImg} alt="RoadMap" />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 ">
                            <div className={styles.whiteCard}>
                                <span>Operations & Service Management</span>
                                <img src={operationsImg} alt="RoadMap" />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 ">
                            <div className={styles.whiteCard}>
                                <span>Cybersecurity & Compliance</span>
                                <img src={cybersecurityImg} alt="RoadMap" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.sectionSeven}>
                    <img alt="laptopImg" src={laptopImg} />
                    <h1>
                        At the heart of every successful deal is a <span className="text-decoration-underline">fully</span> populated data room
                    </h1>
                    <p>
                        At the core of every successful deal lies a meticulously populated data room. A well-populated data room isn't just a repository of information; it's your
                        key to boosting investor confidence. By presenting comprehensive, organized data, you instill trust, demonstrate preparedness, and significantly enhance
                        your chances of sealing the deal.
                        <br />
                        Optimize your diligence, enhance your security, and unlock your success with Lens.
                    </p>
                </div>
                <div className={styles.sectionEight}>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 mb-4">
                            <div className={styles.sectionEightCard}>
                                <h4>Data Protection at its Best</h4>
                                <p>Experience top-tier security with advanced features like encryption, access controls, and multi-factor authentication.</p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-4">
                            <div className={styles.sectionEightCard}>
                                <h4>Simplified Data Management</h4>
                                <p>Navigate your data room effortlessly with an intuitive design, ensuring quick access and efficient collaboration.</p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-4">
                            <div className={styles.sectionEightCard}>
                                <h4>Tailored to Your Needs</h4>
                                <p>Adapt your data room's structure and permissions to match your unique project requirements.</p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-4">
                            <div className={styles.sectionEightCard}>
                                <h4>Unveil Transparency</h4>
                                <p>Gain complete transparency and accountability with detailed activity tracking.</p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-4">
                            <div className={styles.sectionEightCard}>
                                <h4>Foster Teamwork</h4>
                                <p>Efficiently collaborate with your team, consultants, and investors, all within the same environment.</p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-4">
                            <div className={styles.sectionEightCard}>
                                <h4>All-in-One Solution</h4>
                                <p>Accommodate multiple workstreams and custom folders, making it perfect for any project or industry.</p>
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        <button onClick={handleGetDemo} className={styles.fullSizeBtn}>
                            Get a Demo
                        </button>
                    </div>
                </div>
                <div className={styles.sectionNine}>
                    <div className={styles.testimonial}>
                        <span>Testimonial</span>
                        <p>
                            When I was asked to prepare my business for investment I faced an empty dataroom and didn’t have the right materials ready. Thanks to Lens I was able to
                            quickly sort this out.
                        </p>
                        <div className={styles.testimonialByBody}>
                            <img src={personTwo} alt="person" />
                            <div>
                                <p>Colin Shove</p>
                                <p> Founder</p>
                                <p> Salon App</p>
                            </div>
                        </div>
                    </div>
                    <MarqueeComponent />
                    <div className={styles.sectionNineBlue}>
                        <div className={styles.blueSection}>
                            <h3 className="mb-0">Why wait for investor due diligence to uncover red flags?</h3>
                            <p>
                                Lens is the founders' ultimate exit preparation tool, covering everything from Strategy & Roadmap to Financials in one intuitive Virtual Data Room.
                                Say goodbye to fragmented processes and hello to efficient success with Lens.
                            </p>
                            <button onClick={handleGetDemo}>Get a Demo</button>
                        </div>
                        <img src={warnImg} alt="icons" className="position-absolute top-0 " />
                    </div>
                    <div className={styles.testimonial}>
                        <span>Testimonial</span>
                        <p>
                            Great due diligence leaves the management team a sharper view of their business and enhanced motivation to achieve its full potential – the Lens team
                            does great due diligence.
                        </p>
                        <div className={styles.testimonialByBody}>
                            <img src={personThree} alt="person" />
                            <div>
                                <p> John Reynolds</p>
                                <p> Founder & COO</p>
                                <p> Coadjute</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.sectionTen}>
                    <div className="row m-0 p-0">
                        <div className="col-sm-12 col-md-6 m-0 p-0">
                            <div className={styles.sectionTenContent}>
                                <div>
                                    <h3>Evaluate Your Company's Standing</h3>
                                    <p>
                                        Discover your company's current position and pinpoint key areas to prioritize, all before showcasing your most prized asset to potential
                                        investors and buyers.
                                    </p>
                                    <button className={styles.fullSizeBtn} onClick={handleNavigateToToolkit}>
                                        Discover your Investment-Ready Score
                                    </button>
                                </div>{' '}
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 m-0 p-0">
                            <img src={section10BG} className={styles.sectionThreeImage} alt="section10BG" />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
export const MarqueeComponent = () => {
    return (
        <div className={styles.logoCarousel}>
            <Marquee gradient={true}>
                <div className={styles.marqueeLogo}>
                    <img src={comLogo1} alt="Logo 1" height={48} width={300} />
                </div>
                <div className={styles.marqueeLogo}>
                    <img src={comLogo2} alt="Logo 2" height={48} width={291} />
                </div>
                <div className={styles.marqueeLogo}>
                    <img src={comLogo3} alt="Logo 3" height={64} width={183} />
                </div>
                <div className={styles.marqueeLogo}>
                    <img src={comLogo4} alt="Logo 4" height={64} width={172} />
                </div>
                <div className={styles.marqueeLogo}>
                    <img src={comLogo5} alt="Logo 5" height={48} width={133} />
                </div>
                <div className={styles.marqueeLogo}>
                    <img src={comLogo6} alt="Logo 6" height={48} width={270} />
                </div>
            </Marquee>
        </div>
    );
};
const VideoComponent = () => {
    const [show, setShow] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <button onClick={handleShow} className={styles.fullSizeBtn + ' m-0'}>
                <BsPlayCircle size={14} className="me-2" />
                Watch the Video
            </button>
            <Modal centered size="lg" show={show} onHide={handleClose}>
                <Modal.Body>
                    <div>
                        <iframe
                            width="100%"
                            height="500"
                            src="https://www.youtube.com/embed/tVRj7NCuexA?si=8oOLjwtr-6M9QtIr"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen></iframe>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default Home;
