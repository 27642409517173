import styles from './index.module.css';
import React, { useEffect } from 'react';
import Layout from '../../Components/Layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowDroprightCircle } from 'react-icons/io';
import cardOneImg from './assests/cardImg1.jpg';
import cardTwoImg from './assests/cardImg2.jpg';
import cardThreeImg from './assests/cardImg3.jpg';
import cardFourImg from './assests/cardImg4.png';
import cardFiveImg from './assests/cardImg5.png';
import cardSixImg from './assests/cardImg6.png';
import cardSevenImg from './assests/cardImg7.png';
import cardEightImg from './assests/cardImg8.png';
import warnImg from '../Home/assests/warn.png';
const Resources = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    const handleGetDemo = () => {
        window.open('https://meetings.hubspot.com/hutton130', '_blank');
    };

    const handleNavigate = num => {
        navigate(`/resources/download/${num}`);
    };
    return (
        <Layout>
            <div className={styles.sectionOne}>
                <div className={styles.sectionOneContent}>
                    <h3>Resources for Founders and Investors</h3>
                    <p>Browse our latest guides, scorecards, and podcasts to help you maximise your Business Value. Curated for Founders, CEOs, CXOs, and Investors.</p>
                </div>
            </div>
            <div className={styles.sectionTwo}>
                <div className="row justify-content-center">
                    <div className="col-sm-12 col-md-4 mb-5">
                        <div role="button" onClick={() => window.open('https://toolkit.lensapp.ai/', '_blank')} className={styles.sectionTwoCard}>
                            <span className={styles.cardTitle}>Socrecard</span>
                            <img alt="img" src={cardOneImg} />
                            <div className={styles.cardContent}>
                                <h5>The CTO Scorecard</h5>
                                <p>This Scorecard will highlight your blind spots and provide instant, actionable steps to improve</p>
                            </div>
                            <div className={styles.dummyDiv}></div>
                            <div className={styles.arrowBody}>
                                {' '}
                                <IoIosArrowDroprightCircle color="var(--secondary-alpha)" size={48} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 mb-5">
                        <div role="button" onClick={() => handleNavigate(1)} className={styles.sectionTwoCard}>
                            <span className={styles.cardTitle}>Guide</span>
                            <img alt="img" src={cardTwoImg} />
                            <div className={styles.cardContent}>
                                <h5>Your Rock-Solid Exit Strategy</h5>
                                <p>A Step-by-Step template that will help maximise your exit value.</p>
                            </div>
                            <div className={styles.dummyDiv}></div>
                            <div className={styles.arrowBody}>
                                {' '}
                                <IoIosArrowDroprightCircle color="var(--secondary-alpha)" size={48} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 mb-5">
                        <div onClick={() => handleNavigate(2)} role="button" className={styles.sectionTwoCard}>
                            <span className={styles.cardTitle}>Guide</span>
                            <img alt="img" src={cardThreeImg} />
                            <div className={styles.cardContent}>
                                <h5>10 Common IT Due Diligence Problems & Solutions</h5>
                                <p>Early identification of tech issues in startups improves practices, minimises risks for investments or future exits.</p>
                            </div>
                            <div className={styles.dummyDiv}></div>
                            <div className={styles.arrowBody}>
                                {' '}
                                <IoIosArrowDroprightCircle color="var(--secondary-alpha)" size={48} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 mb-5">
                        <div
                           
                            className={styles.sectionTwoCard}>
                            <span className={styles.cardTitle}>Infographic</span>
                            <img alt="img" src={cardEightImg} />
                            <div className={styles.cardContent}>
                                <h5>10 Issues Found During IT Due Diligence and How to Address Them</h5>
                                <p>Learn common red flags that you can address yourself.</p>
                            </div>
                            <div className={styles.dummyDiv}></div>
                            <div className={styles.arrowBody}>
                                {' '}
                                <div
                                    class="hs-cta-embed hs-cta-simple-placeholder hs-cta-embed-87835759338"
                                    style={{ maxWidth: '100%', maxHeight: '100%', width: '160px', height: '42px' }}
                                    data-hubspot-wrapper-cta-id="87835759338">
                                    <a
                                        href="https://cta-eu1.hubspot.com/web-interactives/public/v1/track/redirect?encryptedPayload=AVxigLLlYJTwxHmLEBEyRkf3kITnRnrk5IUpkM8RWT%2FG2KWGGDFXXhXrK8j4WdpLE5eClluDGF%2BuwNjHzrUgbcvWzsptV0tgzQ2gdaxWYB10dMfJcYUBwrAe5Afo03zK3NqsO0rnOhDk3bPYR9dXwsYD%2BjtqK1NfWvkb0ZFDPNanToe1gySqG1h0qGpg6emMv8aGUtCMTsolKLY5W7RPZKwMbt1Brvt%2F%2FjABeL15BM9ZOqh46hAG17Sp5vbWssxATmj0kk6nxZKlrcEtlHXxtCeWZSVuKcpSYsr5KjCBtR0YMQuteckBNl1kqpaF9d%2FnUsQVbjfFdeZhJ88XbsUeR0%2Bye7pkW1HepXGcWe49x8MPeQ%3D%3D&webInteractiveContentId=87835759338&portalId=143426573"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        crossorigin="anonymous">
                                        <img
                                            alt="Download"
                                            loading="lazy"
                                            src="https://hubspot-no-cache-eu1-prod.s3.amazonaws.com/cta/default/143426573/interactive-87835759338.png"
                                            style={{ height: '100%', width: '100%', objectFit: 'fill' }}
                                            onerror="this.style.display='none'"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-4 mb-5">
                        <div
                           
                            className={styles.sectionTwoCard}>
                            <span className={styles.cardTitle}>Infographic</span>
                            <img alt="img" src={cardFiveImg} />
                            <div className={styles.cardContent}>
                                <h5>Evidence of the IT Budget for Tech Due Diligence</h5>
                                <p>
                                    Founders often lose out due to a lack of a cohesive tech budget 'narrative'. This is quick guide informs you what you need to prepare before
                                    negotiations.
                                </p>
                            </div>
                            <div className={styles.dummyDiv}></div>
                            <div className={styles.arrowBody}>
                                <div
                                    class="hs-cta-embed hs-cta-simple-placeholder hs-cta-embed-87836732605"
                                    style={{ maxWidth: '100%', maxHeight: '100%', width: '160px', height: '42px' }}
                                    data-hubspot-wrapper-cta-id="87836732605">
                                    <a
                                        href="https://cta-eu1.hubspot.com/web-interactives/public/v1/track/redirect?encryptedPayload=AVxigLLngwUChSAUEhY9JBAB0Bww9oK4dZDRT3NQvE0%2BUVVCsESd5XAlQDP3TSCniXFyA7I3VxVHwxdoU0W1HYEFKGJ%2Fa7Ite7v02G8SenPXqZTZrAqksGTDH1QfSHXShO8CTv%2F3j6XZ3KVwC%2Bnn84N7sqeL3VcOpd86NF2iCwR7xjZ2Ov8l%2BM2lwJfSyAMHXuBfbsGB%2F8NlvXDGSyUCV3w25Fy7ZFKx2WZOs80oK%2BjhtejbhJoqibHRKzIgEcNimqeo0KGd5Ng8FjQ299jOTXvDzzcyoYK8HMKh2Ajkz8zejAIeV%2BJR2Lnfyz7tv0wkom4M0BpNC%2Bw0%2B2rY83h8ZYs3lr3h5Kwmc%2FtbzvySQ75wYxVHA7die%2Bdzrw%3D%3D&webInteractiveContentId=87836732605&portalId=143426573"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        crossorigin="anonymous">
                                        <img
                                            alt="Download"
                                            loading="lazy"
                                            src="https://hubspot-no-cache-eu1-prod.s3.amazonaws.com/cta/default/143426573/interactive-87836732605.png"
                                            style={{ height: '100%', width: '100%', objectFit: 'fill' }}
                                            onerror="this.style.display='none'"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 mb-5">
                        <div
                            className={styles.sectionTwoCard}>
                            <span className={styles.cardTitle}>Infographic</span>
                            <img alt="img" src={cardSixImg} />
                            <div className={styles.cardContent}>
                                <h5>Tech Due Diligence Essential Success Factors</h5>
                                <p>Pinpoint what need to be in place for a successful investment or exit.</p>
                            </div>
                            <div className={styles.dummyDiv}></div>
                            <div className={styles.arrowBody}>
                                <div
                                    class="hs-cta-embed hs-cta-simple-placeholder hs-cta-embed-87835759328"
                                    style={{ maxWidth: '100%', maxHeight: '100%', width: '160px', height: '42px' }}
                                    data-hubspot-wrapper-cta-id="87835759328">
                                    <a
                                        href="https://cta-eu1.hubspot.com/web-interactives/public/v1/track/redirect?encryptedPayload=AVxigLJ0Xgfb8HM0HfRf%2F4xC1Ds%2B8zsF4WQD7NAMNlRR5Z%2BVV8KYPK75%2BvZF%2BdvOhP0f0o05jdGdOt%2BXYSRITm%2BKinGa1p33XK0mmJbz54KXYYZM2szGA4HJlA5f%2FYeOe%2BNDYVChNaorqpfJ1oiPjGhxDBntVD7mX2Un8Ti7%2B6kv%2BYPL5eFjJ6zudOt0jTOwMGvQHNmsWu5VpgaerpL1%2BD4DhZANgv08MWwyjcYcF7XR6CC0SDA9i4XgOb9YnEgKl6rOTUgCKrubusEGBDLoACpX%2FxE%2Bdo4NU%2Fw9VFard7GMecO2cQnRxhbkJm5r7POKUK%2B%2F5vWTw6hdpgdeGoqFccRfjj8cpqoo7L93Ur1wb%2Fp%2FD5NOnqIXVg%3D%3D&webInteractiveContentId=87835759328&portalId=143426573"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        crossorigin="anonymous">
                                        <img
                                            alt="Download"
                                            loading="lazy"
                                            src="https://hubspot-no-cache-eu1-prod.s3.amazonaws.com/cta/default/143426573/interactive-87835759328.png"
                                            style={{ height: '100%', width: '100%', objectFit: 'fill' }}
                                            onerror="this.style.display='none'"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 mb-5">
                        <div
                          
                            className={styles.sectionTwoCard}>
                            <span className={styles.cardTitle}>Infographic</span>
                            <img alt="img" src={cardSevenImg} />
                            <div className={styles.cardContent}>
                                <h5>Common Issues Observed in Early Stage Firms During IT Due Diligence</h5>
                                <p>Be prepared for tech due diligence.</p>
                            </div>
                            <div className={styles.dummyDiv}></div>
                            <div className={styles.arrowBody}>
                                <div
                                    class="hs-cta-embed hs-cta-simple-placeholder hs-cta-embed-87836732405"
                                    style={{ maxWidth: '100%', maxHeight: '100%', width: '160px', height: '42px' }}
                                    data-hubspot-wrapper-cta-id="87836732405">
                                    <a
                                        href="https://cta-eu1.hubspot.com/web-interactives/public/v1/track/redirect?encryptedPayload=AVxigLICkpnbUuDXtDfIzh%2BqunRTMARWiwxsdYcWhg6GXZ98sfzX%2BPDOlWbbvnfQvC5ZoMQEK5KtoLpzTyTC9YoqUculXKkjrFqmiKtsr9%2BC1JPVGYst7k9%2BVPcIuj6ewcVaoUF2Ijt5%2BgRKYkjWdgbZTOuVJo85YlQjLgKTfdTw%2Bh25csYAdlP3ZwnHYUlkcNXo7LjAyeZfQea8JpVdJ8twgvM6FT%2BAklMOXKhNDcDt0s%2Bbl3%2BYnxRXx9y8EgkQ1AcHmVx%2BX7i7nxuZ3IobRR8k0%2BTyEFY7FGonmKvOSdpPYiqt7idWgyAyl%2BduEevMWYbUYDKUsiA2Wr5yhs1QZUmHxjHPW1qhgHcRrFD%2F9od9DVmvgISZKgd3LiAaXQdq7pHdlYjA9Z6AkqNT9XKpc5PpLTccIutyx%2F0Hfg%3D%3D&webInteractiveContentId=87836732405&portalId=143426573"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        crossorigin="anonymous">
                                        <img
                                            alt="Download"
                                            loading="lazy"
                                            src="https://hubspot-no-cache-eu1-prod.s3.amazonaws.com/cta/default/143426573/interactive-87836732405.png"
                                            style={{ height: '100%', width: '100%', objectFit: 'fill' }}
                                            onerror="this.style.display='none'"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 mb-5">
                        <div
                            className={styles.sectionTwoCard}>
                            <span className={styles.cardTitle}>Infographic</span>
                            <img alt="img" src={cardFourImg} />
                            <div className={styles.cardContent}>
                                <h5>Future Categories for Tech Due Diligence</h5>
                                <p>In this infographic we explore areas that will become future considerations when building your tech company.</p>
                            </div>
                            <div className={styles.dummyDiv}></div>
                            <div className={styles.arrowBody}>
                                {' '}
                                <div
                                    class="hs-cta-embed hs-cta-simple-placeholder hs-cta-embed-87834929608"
                                    style={{ maxWidth: '100%', maxHeight: '100%', width: '160px', height: '42px' }}
                                    data-hubspot-wrapper-cta-id="87834929608">
                                    <a
                                        href="https://cta-eu1.hubspot.com/web-interactives/public/v1/track/redirect?encryptedPayload=AVxigLK7o6o%2BvDRR3t%2FzMfW3PvXnXXcY4KpPbeUwyJs44OlmpwrE1ITs2XgYvDsw%2B%2FMD2YwxWHb210%2FCxiw4JKaLxH%2BXiiH6Ulc1b6yebiLvBafmddrLlit1qJXPlkIDYhv4tZaPnWWphn%2Fl1aFkUb9mdyP%2BsC%2Fm9Xi6F%2FcSC5pW953rHjyDD%2FOssh0PK%2FvgUPhKzSi5vJ9p0IzmMKb5z0wdyr3itrqhZDaSz0xhXw1RtXWJV13Uaw9qHQIgsUAMG5Ii11GOY%2BANJt%2BBr99aFwgxojZZQ3Z5cChFYVitX8%2BI8aKRQ4dc9JvRdrfmcVfW8bhpgc871D%2BlI2PS6j8PIqAMPRix87tg%2FJICOdqmITu%2F&webInteractiveContentId=87834929608&portalId=143426573"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        crossorigin="anonymous">
                                        <img
                                            alt="Download"
                                            loading="lazy"
                                            src="https://hubspot-no-cache-eu1-prod.s3.amazonaws.com/cta/default/143426573/interactive-87834929608.png"
                                            style={{ height: '100%', width: '100%', objectFit: 'fill' }}
                                            onerror="this.style.display='none'"
                                        />
                                    </a>
                                </div>
                                {/* <IoIosArrowDroprightCircle  color="var(--secondary-alpha)" size={48} /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.sectionThree}>
                <div className={styles.sectionThreeBlue}>
                    <div className={styles.blueSection}>
                        <h3 className="mb-0">Why wait for investor due diligence to uncover red flags?</h3>
                        <p>
                            Lens is the founders' ultimate exit preparation tool, covering everything from Strategy & Roadmap to Financials in one intuitive Virtual Data Room. Say
                            goodbye to fragmented processes and hello to efficient success with Lens.
                        </p>
                        <button onClick={handleGetDemo}>Get a Demo</button>
                    </div>
                    <img src={warnImg} alt="icons" className="position-absolute top-0 " />
                </div>
            </div>
        </Layout>
    );
};

export default Resources;
