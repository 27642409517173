import './App.css';

import Home from './Pages/Home';
import Contact from './Pages/Contact';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Pricing from './Pages/Pricing';
import DownloadResource from './Pages/DownloadResource';
import Resources from './Pages/Resources';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
function App() {
    useEffect(() => {
        const loadScript = () => {
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.id = 'hs-script-loader';
          script.async = true;
          script.defer = true;
          script.src = '//js-eu1.hs-scripts.com/143426573.js';
          document.body.appendChild(script);
        };
    
        // Load the script after 10 seconds
        const timeoutId = setTimeout(loadScript, 10000);
    
        // Clear the timeout if the component unmounts before 10 seconds
        return () => clearTimeout(timeoutId);
      }, []); // Empty dependency array ensures the effect runs once after initial render
 
    return (
        <>
            <BrowserRouter>
                <ToastContainer />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/resources"  element={<Resources />} />
                    <Route path="/resources/download/:number" element={<DownloadResource />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
