/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import Layout from '../../Components/Layout';
import pricingManImg from './assests/pricingMan.jpg';
import styles from './index.module.css';
import { BsCheckCircleFill } from 'react-icons/bs';
import { TiMinus } from 'react-icons/ti';
import { AiOutlinePlus } from 'react-icons/ai';
import { LastSection } from '../Contact';
import { MarqueeComponent } from '../Home';
import { useLocation, useNavigate } from 'react-router-dom';
const Pricing = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    
    return (
        <Layout>
            {/* <Helmet>
<script src="https://js.chargebee.com/v2/chargebee.js" data-cb-site="lens" ></script>

</Helmet> */}
            <div className={styles.sectionOne}>
                <div className="row m-0 p-0">
                    <div className="col-sm-12 col-md-6 m-0 p-0">
                        <div className={styles.sectionOneContent}>
                            <div>
                                <h3>Get Ready for Investment.</h3>
                                <p>
                                    Maximise your business value with Lens. Whether you're running a startup, mid-cap or Enterprise there is package especially for you. So that you
                                    can prepare your business for diligence, on your terms and in your own time.
                                </p>
                            </div>{' '}
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 m-0 p-0">
                        <img src={pricingManImg} className={styles.sectionOneImage} alt="pricingManImg" />
                    </div>
                </div>
            </div>
            <div className={styles.sectionTwoWeb}>
                <PricingWeb />
                <PricingMobile />
                <div className={styles.accodonDiv}>
                    <p className={styles.addOnsHeading}>Other available add-ons:</p>
                    <div>
                        <div className={styles.addOnsText}>
                            <AiOutlinePlus className="me-3" />
                            <span>CTO Coaching</span>
                        </div>
                        <div className={styles.addOnsText}>
                            <AiOutlinePlus className="me-2" />
                            <span>Back Office IT Services</span>
                        </div>
                        <div className={styles.addOnsText}>
                            <AiOutlinePlus className="me-2" />
                            <span>Due Diligence Assessment</span>
                        </div>
                        <div className={styles.addOnsText}>
                            <AiOutlinePlus className="me-2" />
                            <span>Exit Readiness Assessment</span>
                        </div>
                        <div className={styles.addOnsText}>
                            <AiOutlinePlus className="me-2" />
                            <span>Consulting</span>
                        </div>
                    </div>
                </div>
            </div>
            <LastSection />
            <div className={styles.marqueeBody}>
                <MarqueeComponent />
            </div>
        </Layout>
    );
};
const PricingWeb = () => {

 

    return (
        <table className={styles.tableBody}>
          
            <thead>
                <tr>
                    <td></td>
                    <td style={{ width: 330 }}>
                        <div className={styles.priceCardHeader}>Lens Basic</div>
                    </td>
                    <td style={{ width: 330 }}>
                        <div className={styles.priceCardHeader} style={{ background: 'var(--secondary-alpha)' }}>
                            Lens Scale-Up
                        </div>
                    </td>
                    <td style={{ width: 330 }}>
                        <div className={styles.priceCardHeader} style={{ background: 'var(--tertiary-alpha)' }}>
                            Lens Enterprise
                        </div>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td></td>
                    <td className={styles.rowItem}>
                        <div className={styles.cardDesc}>Best suited for Early-Stage Startups, SMBs with limited resources & Founders looking to get started.</div>
                    </td>
                    <td className={styles.rowItem}>
                        <div className={styles.cardDesc}>
                            Best suited for Growing Startups, Multi-project SMEs, Seasoned Founders, Comprehensive Readiness, and Multi-round Investments.
                        </div>
                    </td>
                    <td className={styles.rowItem}>
                        <div className={styles.cardDesc}>
                            Best suited for Large Enterprises, Businesses with high transaction volumes, Seasoned Consultants, Corporate Investors
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className={styles.rowItem}></td>
                    <td className={styles.rowItem}>
                        <div className={styles.priceDiv}>
                            <h3>£ 120</h3>
                            <span>per month</span>

                            {/* <span onClick={openChargebeePortal}>Manage</span> */}
                            {/* // eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <p className="m-0">
                                <button className={styles.subScribeButton} data-cb-type="checkout" data-cb-item-0="VDR-Basic-GBP-Monthly" data-cb-item-0-quantity="1">
                                    Subscribe
                                </button>
                                {/* <a href="javascript:void(0)" data-cb-type="checkout" data-cb-item-0="VDR-Basic-GBP-Monthly" data-cb-item-0-quantity="1" >Subscribe</a> */}
                            </p>
                            {/* <a href="javascript:void(0)" data-cb-type="portal" >Manage account</a>
                            <a href="javascript:void(0)" data-cb-type="checkout" data-cb-item-0="VDR-Basic-GBP-Monthly" data-cb-item-0-quantity="1" >subscribe</a> */}
                            {/* <a href="javascript:void(0)" data-cb-type="checkout" data-cb-item-0="VDR-Basic-GBP-Monthly" data-cb-item-0-quantity="1" data-cb-item-1="VDRBasic-GBP" >Free Trial</a> */}
                        </div>
                    </td>
                    <td className={styles.rowItem}>
                        <div className="text-center">
                            <h3 className={styles.comingSoonText}>Coming Soon</h3>
                        </div>
                    </td>
                    <td className={styles.rowItem}>
                        <div className="text-center">
                            <h3 className={styles.comingSoonText}>Coming Soon</h3>
                        </div>
                    </td>
                </tr>
                <tr className={styles.hoverableRow}>
                    <td className={styles.headingTextRowItem}>Projects</td>
                    <td className={styles.detailRowItem}>1</td>
                    <td className={styles.detailRowItem}>5</td>
                    <td className={styles.detailRowItem}>Unlimited</td>
                </tr>

                <tr className={styles.hoverableRow}>
                    <td className={styles.headingTextRowItem}>Users</td>
                    <td className={styles.detailRowItem}>10</td>
                    <td className={styles.detailRowItem}>100</td>
                    <td className={styles.detailRowItem}>Unlimited</td>
                </tr>
                <tr className={styles.hoverableRow}>
                    <td className={styles.headingTextRowItem}>Admin Accounts</td>
                    <td className={styles.detailRowItem}>1</td>
                    <td className={styles.detailRowItem}>5</td>
                    <td className={styles.detailRowItem}>Unlimited</td>
                </tr>
                <tr className={styles.hoverableRow}>
                    <td className={styles.headingTextRowItem}>Virtual Data Room</td>
                    <td className={styles.detailRowItem}>
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                    <td className={styles.detailRowItem}>
                        {' '}
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                    <td className={styles.detailRowItem}>
                        {' '}
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                </tr>
                <tr className={styles.hoverableRow}>
                    <td className={styles.headingTextRowItem}>Mandated 2FA Login</td>
                    <td className={styles.detailRowItem}>
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                    <td className={styles.detailRowItem}>
                        {' '}
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                    <td className={styles.detailRowItem}>
                        {' '}
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                </tr>
                <tr className={styles.hoverableRow}>
                    <td className={styles.headingTextRowItem}>Granular Security Permissions</td>
                    <td className={styles.detailRowItem}>
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                    <td className={styles.detailRowItem}>
                        {' '}
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                    <td className={styles.detailRowItem}>
                        {' '}
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                </tr>
                <tr className={styles.hoverableRow}>
                    <td className={styles.headingTextRowItem}>Information Request List (IRL)</td>
                    <td className={styles.detailRowItem}>
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                    <td className={styles.detailRowItem}>
                        {' '}
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                    <td className={styles.detailRowItem}>
                        {' '}
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                </tr>
                <tr className={styles.hoverableRow}>
                    <td className={styles.headingTextRowItem}>Task Management</td>
                    <td className={styles.detailRowItem}>
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                    <td className={styles.detailRowItem}>
                        {' '}
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                    <td className={styles.detailRowItem}>
                        {' '}
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                </tr>
                <tr className={styles.hoverableRow}>
                    <td className={styles.headingTextRowItem}>Workstream Analytics</td>
                    <td className={styles.detailRowItem}>
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                    <td className={styles.detailRowItem}>
                        {' '}
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                    <td className={styles.detailRowItem}>
                        {' '}
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                </tr>
                <tr className={styles.hoverableRow}>
                    <td className={styles.headingTextRowItem}>NDA Management</td>
                    <td className={styles.detailRowItem}>
                        <TiMinus color="#C6C8D2" />
                    </td>
                    <td className={styles.detailRowItem}>
                        {' '}
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                    <td className={styles.detailRowItem}>
                        {' '}
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                </tr>
                <tr className={styles.hoverableRow}>
                    <td className={styles.headingTextRowItem}>Company Information</td>
                    <td className={styles.detailRowItem}>
                        <TiMinus color="#C6C8D2" />
                    </td>
                    <td className={styles.detailRowItem}>
                        {' '}
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                    <td className={styles.detailRowItem}>
                        {' '}
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                </tr>
                <tr className={styles.hoverableRow}>
                    <td className={styles.headingTextRowItem}>Observations Record</td>
                    <td className={styles.detailRowItem}>
                        <TiMinus color="#C6C8D2" />
                    </td>
                    <td className={styles.detailRowItem}>
                        {' '}
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                    <td className={styles.detailRowItem}>
                        {' '}
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                </tr>
                <tr className={styles.hoverableRow}>
                    <td className={styles.headingTextRowItem}>RAG Management</td>
                    <td className={styles.detailRowItem}>
                        <TiMinus color="#C6C8D2" />
                    </td>
                    <td className={styles.detailRowItem}>
                        {' '}
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                    <td className={styles.detailRowItem}>
                        {' '}
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                </tr>
                <tr className={styles.hoverableRow}>
                    <td className={styles.headingTextRowItem}>SWOT Analysis</td>
                    <td className={styles.detailRowItem}>
                        <TiMinus color="#C6C8D2" />
                    </td>
                    <td className={styles.detailRowItem}>
                        {' '}
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                    <td className={styles.detailRowItem}>
                        {' '}
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                </tr>
                <tr className={styles.hoverableRow}>
                    <td className={styles.headingTextRowItem}>Lens Badge</td>
                    <td className={styles.detailRowItem}>
                        <TiMinus color="#C6C8D2" />
                    </td>
                    <td className={styles.detailRowItem}>
                        {' '}
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                    <td className={styles.detailRowItem}>
                        {' '}
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                </tr>
                <tr className={styles.hoverableRow}>
                    <td className={styles.headingTextRowItem}>Biometric Identification</td>
                    <td className={styles.detailRowItem}>
                        <TiMinus color="#C6C8D2" />
                    </td>
                    <td className={styles.detailRowItem}>
                        <TiMinus color="#C6C8D2" />
                    </td>
                    <td className={styles.detailRowItem}>
                        {' '}
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                </tr>
                <tr className={styles.hoverableRow}>
                    <td className={styles.headingTextRowItem}>Single Sign-On (SSO)</td>
                    <td className={styles.detailRowItem}>
                        <TiMinus color="#C6C8D2" />
                    </td>
                    <td className={styles.detailRowItem}>
                        <TiMinus color="#C6C8D2" />
                    </td>
                    <td className={styles.detailRowItem}>
                        {' '}
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                </tr>
                <tr className={styles.hoverableRow}>
                    <td className={styles.headingTextRowItem}>Dedicated Success Partner</td>
                    <td className={styles.detailRowItem}>
                        <TiMinus color="#C6C8D2" />
                    </td>
                    <td className={styles.detailRowItem}>
                        <TiMinus color="#C6C8D2" />
                    </td>
                    <td className={styles.detailRowItem}>
                        {' '}
                        <BsCheckCircleFill color="var(--primary-alpha)" />
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td style={{ width: 330 }}>
                        <div className={styles.priceCardHeader}>Lens Basic</div>
                    </td>
                    <td style={{ width: 330 }}>
                        <div className={styles.priceCardHeader} style={{ background: 'var(--secondary-alpha)' }}>
                            Lens Scale-Up
                        </div>
                    </td>
                    <td style={{ width: 330 }}>
                        <div className={styles.priceCardHeader} style={{ background: 'var(--tertiary-alpha)' }}>
                            Lens Enterprise
                        </div>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td className={styles.rowItem}>
                        <div className={styles.priceDiv}>
                            <h3>£ 120</h3>
                            <span>per month</span>
                            <p className="m-0">
                                <button className={styles.subScribeButton} data-cb-type="checkout" data-cb-item-0="VDR-Basic-GBP-Monthly" data-cb-item-0-quantity="1">
                                    Subscribe
                                </button>
                            </p>
                        </div>
                    </td>
                    <td className={styles.rowItem}>
                        <div className="text-center">
                            <h3 className={styles.comingSoonText}>Coming Soon</h3>
                        </div>
                    </td>
                    <td className={styles.rowItem}>
                        <div className="text-center">
                            <h3 className={styles.comingSoonText}>Coming Soon</h3>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};
const PricingMobile = () => {
    const navigate = useNavigate();
    return (
        <div className={styles.mobilePricingBody}>
            <div className={styles.pricingCard}>
                <div className={styles.pricingCardHeader}>Lens Basic</div>
                <div className={styles.cardDesc}>Best suited for Early-Stage Startups, SMBs with limited resources & Founders looking to get started.</div>
                <br />
                <div className={styles.priceDiv}>
                    <h3>£ 120</h3>
                    <span>per month</span>
                </div>
                <div className={styles.dashsedLine} />
                <div className="ps-3 pe-3">
                    <p className={styles.mobileChracteristics}>1 Project</p>
                    <p className={styles.mobileChracteristics}>10 Users</p>
                    <p className={styles.mobileChracteristics}>1 Admin Account</p>
                    <p className={styles.mobileChracteristics}>Virtual Data Room</p>
                    <p className={styles.mobileChracteristics}>Mandated 2FA login</p>
                    <p className={styles.mobileChracteristics}>Granular Security Permissions</p>
                    <p className={styles.mobileChracteristics}></p>
                </div>
                <div className={styles.advisoryDiv}>Additional Discovery Tools</div>
                <div className="ps-3 pe-3">
                    <p className={styles.mobileChracteristics}>Information Request List (IRL)</p>
                    <p className={styles.mobileChracteristics}>Task Management</p>
                    <p className={styles.mobileChracteristics}>Workstream Analytics</p>
                </div>
                <div className={styles.pricingCardHeader + ' mt-5'} style={{ borderRadius: 0 }}>
                    Lens Basic
                </div>
                <br />
                <div className={styles.priceDiv}>
                    <h3>£ 120</h3>
                    <span>per month</span>
                </div>
                <div className="text-center">
                    {' '}
                    <button className={styles.signUpbtn}>Sign Up</button>
                </div>
            </div>
            <br />
            <br />
            <div className={styles.pricingCard} style={{ borderColor: 'var(--secondary-alpha)' }}>
                <div className={styles.pricingCardHeader} style={{ background: 'var(--secondary-alpha)' }}>
                    Lens Scale-up
                </div>
                <div className={styles.cardDesc}>
                    Best suited for Growing Startups, Multi-project SMEs, Seasoned Founders, Comprehensive Readiness, and Multi-round Investments.
                </div>
                <div className="text-center mt-2">
                    <button className={styles.contactSalesBtn} onClick={() => navigate('/contact')}>
                        Contact Sales
                    </button>
                </div>
                <div className={styles.dashsedLine} />
                <div className="ps-3 pe-3">
                    <p className={styles.mobileChracteristics}>5 Project</p>
                    <p className={styles.mobileChracteristics}>100 Users</p>
                    <p className={styles.mobileChracteristics}>5 Admin Account</p>
                    <p className={styles.mobileChracteristics}>Virtual Data Room</p>
                    <p className={styles.mobileChracteristics}>Mandated 2FA login</p>
                    <p className={styles.mobileChracteristics}>Granular Security Permissions</p>
                    <p className={styles.mobileChracteristics}></p>
                </div>
                <div className={styles.advisoryDiv}>Additional Discovery Tools</div>
                <div className="ps-3 pe-3">
                    <p className={styles.mobileChracteristics}>Information Request List (IRL)</p>
                    <p className={styles.mobileChracteristics}>Task Management</p>
                    <p className={styles.mobileChracteristics}>Workstream Analytics</p>
                    <p className={styles.mobileChracteristics}>NDA Management</p>
                    <p className={styles.mobileChracteristics}>Company Information</p>
                    <p className={styles.mobileChracteristics}>Observations Record</p>
                    <p className={styles.mobileChracteristics}>RAG Management</p>
                    <p className={styles.mobileChracteristics}>SWOT Analysis</p>
                    <p className={styles.mobileChracteristics}>Lens Badge</p>
                </div>
                <div className={styles.pricingCardHeader + ' mt-5'} style={{ borderRadius: 0, background: 'var(--secondary-alpha)' }}>
                    Lens Scale-up
                </div>
                <div className="text-center mt-4">
                    <button className={styles.contactSalesBtn} onClick={() => navigate('/contact')}>
                        Contact Sales
                    </button>
                </div>
            </div>
            <br />
            <br />
            <div className={styles.pricingCard} style={{ borderColor: 'var(--tertiary-alpha)' }}>
                <div className={styles.pricingCardHeader} style={{ background: 'var(--tertiary-alpha)' }}>
                    Lens Enterprise
                </div>
                <div className={styles.cardDesc}>Best suited for Large Enterprises, Businesses with high transaction volumes, Seasoned Consultants, Corporate Investors</div>
                <div className="text-center mt-2">
                    <button className={styles.contactSalesBtn} onClick={() => navigate('/contact')}>
                        Contact Sales
                    </button>
                </div>
                <div className={styles.dashsedLine} />
                <div className="ps-3 pe-3">
                    <p className={styles.mobileChracteristics}>Unlimited Project</p>
                    <p className={styles.mobileChracteristics}>Unlimited Users</p>
                    <p className={styles.mobileChracteristics}>Unlimited Admin Account</p>
                    <p className={styles.mobileChracteristics}>Virtual Data Room</p>
                    <p className={styles.mobileChracteristics}>Mandated 2FA login</p>
                    <p className={styles.mobileChracteristics}>Granular Security Permissions</p>
                    <p className={styles.mobileChracteristics}></p>
                </div>
                <div className={styles.advisoryDiv}>Additional Discovery Tools</div>
                <div className="ps-3 pe-3">
                    <p className={styles.mobileChracteristics}>Information Request List (IRL)</p>
                    <p className={styles.mobileChracteristics}>Task Management</p>
                    <p className={styles.mobileChracteristics}>Workstream Analytics</p>
                    <p className={styles.mobileChracteristics}>NDA Management</p>
                    <p className={styles.mobileChracteristics}>Company Information</p>
                    <p className={styles.mobileChracteristics}>Observations Record</p>
                    <p className={styles.mobileChracteristics}>RAG Management</p>
                    <p className={styles.mobileChracteristics}>SWOT Analysis</p>
                    <p className={styles.mobileChracteristics}>Lens Badge</p>
                    <p className={styles.mobileChracteristics}>Biometric Identification</p>
                    <p className={styles.mobileChracteristics}>Single Sign-on (SSO)</p>
                    <p className={styles.mobileChracteristics}>Biometric Identification</p>
                    <p className={styles.mobileChracteristics}>Dedicated Customer Success Manager</p>
                </div>
                <div className={styles.pricingCardHeader + ' mt-5'} style={{ borderRadius: 0, background: 'var(--tertiary-alpha)' }}>
                    Lens Enterprise
                </div>
                <div className="text-center mt-4">
                    <button className={styles.contactSalesBtn} onClick={() => navigate('/contact')}>
                        Contact Sales
                    </button>
                </div>
            </div>
        </div>
    );
};
export default Pricing;
