import styles from './index.module.css';
import React, { useEffect } from 'react';
import Layout from '../../Components/Layout';
import LastSectionImg from '../Home/assests/section3BG.png';
import personTwo from '../Home/assests/person2.png';
import { useLocation, useParams } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa6';
import { MarqueeComponent } from '../Home/index';
import { toast } from 'react-toastify';
import axios from 'axios';
import section2BG1 from './assests/section2BG1.jpg'
import section2BG2 from './assests/section2BG2.jpg'
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
const DownloadResource = () => {

    const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({
        firstName: '',
        lastName: '',
        email: '',
    });

    const handleChangeFields = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        console.log(formData);
        let portalId = '143426573';
        // 
        let formGUID =params?.number==='1'? '015751c7-22d4-46b2-ada4-ccf00d0ddc40':'dea0b511-3db6-429a-9a8c-00dcc5856a20';
        const formApiUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGUID}`;
        try {
            const response = await axios.post(formApiUrl, {
                submittedAt: Date.now(),
                fields: Object.keys(formData).map(name => ({
                    name,
                    value: formData[name],
                })),
                context: {
                    // hutk: ':hutk',
                    pageUri: window.location.href,
                    pageName: document.title,
                },
                legalConsentOptions: {
                    consent: {
                        consentToProcess: true,
                        text: 'I agree to allow Example Company to store and process my personal data.',
                        communications: [
                            {
                                value: true,
                                subscriptionTypeId: 999,
                                text: 'I agree to receive marketing communications from Example Company.',
                            },
                        ],
                    },
                },
            });
            setLoading(false);
            if (response.status === 200) {
                toast.success(params?.number==='1'?`You guide to build a Rock-Solid Exit Strategy is on it's way`:`Your Guide to Tackling Issues Observed during IT Due Diligence is on it's way.`);
            } else {
                toast.error(`Error: ${response.status}, ${response.data}`);
            }
        } catch (error) {
            setLoading(false);
            toast.error('An error occurred: ' + error);
        }
        setFormData({
            firstName: '',
            lastName: '',
            email: '',
          
        });
    };
    const location = useLocation();
    const params = useParams();
    console.log(params)
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <Layout>
            <div className="">
                <div className="row p-0 m-0">
                    <div className="col-sm-12 col-md-6 p-0 m-0">
                        <div className={styles.sectionOne} >
                            <img src={params?.number==='1'?section2BG1:section2BG2} alt='2'/>
                            {/* <div>
                                <h3>Download the Rock-Solid Exit Strategy Guide</h3>
                                <p>A Step-by-Step template that will help maximise your exit value.</p>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 p-0 m-0">
                       
                       <form onSubmit={handleSubmit}>
                       <div className={styles.formBody}>
                       <div className={styles.sectionOneContent}>
                            <h3>
                                {params?.number==='1'? 'Build a Rock-Solid Exit Strategy':'Get Your Hands on the Top 10 IT Challenges Revealed' }
                               </h3>
                            <p>
                            {params?.number==='1'?'A Step-by-Step template that will help maximise your exit value.':'Your Ultimate Guide to Tackling Everyday IT Hurdles Uncovered During Due Diligence'}
                                </p>
                        </div>
                            <div className="mb-2">
                                <label className={styles.label}>
                                    First Name <span>*</span>
                                </label>
                                <input
                                    type="text"
                                    className={'form-control ' + styles.customInput}
                                    name="firstName"
                                    value={formData?.firstName}
                                    onChange={handleChangeFields}
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <label className={styles.label}>
                                    Last Name <span>*</span>
                                </label>
                                <input
                                    type="text"
                                    className={'form-control ' + styles.customInput}
                                    name="lastName"
                                    value={formData?.lastName}
                                    onChange={handleChangeFields}
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <label className={styles.label}>
                                Business E-mail <span>*</span>
                                </label>
                                <input
                                    type="mail"
                                    className={'form-control ' + styles.customInput}
                                    name="email"
                                    value={formData?.email}
                                    onChange={handleChangeFields}
                                    required
                                />
                            </div>
                            <button
                                type="submit"
                                className={styles.submitBtn}
                                 disabled={loading}
                            >
                                
                                {loading ? <AiOutlineLoading3Quarters size={20} className="rotateLoader" /> : 'Download'}
                            </button>
                        </div>
                       </form>
                    </div>
                </div>
              
                <div className={styles.sectionTwo}>
                           <p>
                           {params?.number==='1'?' Founders and CEOs like yourself look to exit but don’t know if their firm is optimised for sale. The key challenge lies in mastering the due diligence process. The Rock-Solid Exit Guide will help you present your business confidently to buyers, ensure your firm doesn’t have embarrassing issues that could impede a deal and prepare rapidly so it doesn’t impact your business.':`In every business lies the potential for technological challenges that could hinder growth or affect future exit strategies. Identifying and addressing these challenges early on not only enhances operational efficiency but also mitigates risks, ensuring a smoother journey for your company's success and attracting potential partners or buyers.` }
                           </p>
                        </div>
                <div className={styles.sectionThree}>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-3">
                            <div className={styles.sectionThreeCard}>
                                <h1>Identify Business Gaps</h1>
                                <div className="position-absolute top-0 end-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="160" height="131" viewBox="0 0 160 131" fill="none">
                                        <g opacity="0.1">
                                            <circle cx="100" cy="31" r="90.4762" stroke="white" strokeWidth="19.0476" />
                                            <circle cx="99.9999" cy="30.9994" r="57.1429" stroke="white" strokeWidth="19.0476" />
                                            <path d="M125 31C125 44.8071 113.807 56 100 56" stroke="white" strokeWidth="19.0476" strokeLinecap="round" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3">
                            <div className={styles.sectionThreeCard}>
                                <h1>Remediate Weaknesses</h1>
                                <div className="position-absolute top-0 end-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="160" height="131" viewBox="0 0 160 131" fill="none">
                                        <g opacity="0.1">
                                            <circle cx="100" cy="31" r="90.4762" stroke="white" strokeWidth="19.0476" />
                                            <circle cx="99.9999" cy="30.9994" r="57.1429" stroke="white" strokeWidth="19.0476" />
                                            <path d="M125 31C125 44.8071 113.807 56 100 56" stroke="white" strokeWidth="19.0476" strokeLinecap="round" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3">
                            <div className={styles.sectionThreeCard}>
                                <h1>Maximise Business Value</h1>
                                <div className="position-absolute top-0 end-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="160" height="131" viewBox="0 0 160 131" fill="none">
                                        <g opacity="0.1">
                                            <circle cx="100" cy="31" r="90.4762" stroke="white" strokeWidth="19.0476" />
                                            <circle cx="99.9999" cy="30.9994" r="57.1429" stroke="white" strokeWidth="19.0476" />
                                            <path d="M125 31C125 44.8071 113.807 56 100 56" stroke="white" strokeWidth="19.0476" strokeLinecap="round" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3">
                            <div className={styles.sectionThreeCard}>
                                <h1>Secure Your Future</h1>
                                <div className="position-absolute top-0 end-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="160" height="131" viewBox="0 0 160 131" fill="none">
                                        <g opacity="0.1">
                                            <circle cx="100" cy="31" r="90.4762" stroke="white" strokeWidth="19.0476" />
                                            <circle cx="99.9999" cy="30.9994" r="57.1429" stroke="white" strokeWidth="19.0476" />
                                            <path d="M125 31C125 44.8071 113.807 56 100 56" stroke="white" strokeWidth="19.0476" strokeLinecap="round" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.sectionFour}>
                    <div className={styles.testimonial}>
                        <span>Testimonial</span>
                        <p>
                        When I was asked to prepare my business for investment I faced an empty dataroom and didn’t have the right materials ready. Thanks to Lens I was able to quickly sort this out.
                        </p>
                        <div className={styles.testimonialByBody}>
                            <img src={personTwo} alt="person" />
                            <div>
                                <p>Colin Shove</p>
                                <p> Founder</p>
                                <p>  Salon App</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-4 mb-4">
                    {' '}
                    <MarqueeComponent />
                </div>
                <div className={styles?.sectionFive}>
                    <div className="row m-0 p-0">
                        <div className="col-sm-12 col-md-6 m-0 p-0">
                            <img src={LastSectionImg} className={styles.sectionFiveImage} alt="section3BG" />
                        </div>
                        <div className="col-sm-12 col-md-6 m-0 p-0">
                            <div className={styles.sectionFiveContent}>
                                <div>
                                    <h3>Reduce the Levels of Risk in Your Business.</h3>
                                    <p role="button" className="d-felx align-items-center" onClick={() => window.open('https://toolkit.lensapp.ai/', '_blank')}>
                                        <span>Discover Your Startup's Exit Readiness Now!</span> <FaArrowRight />
                                    </p>
                                </div>{' '}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default DownloadResource;
