import Layout from '../../Components/Layout';
import styles from './index.module.css';
import CountryCode from './CountryCode.json';
import personTwo from '../Home/assests/person2.png';
import { MarqueeComponent } from '../Home/index';
import warnImg from '../Home/assests/warn.png';
import React, { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
const Contact = () => {
    const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
        country: '',
    });

    const handleChangeCountry = e => {
        console.log(e.target.value);
        setFormData({ ...formData, phone: e.target.value, country: e.target.value });
    };
    const handleChangeFields = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    
    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        console.log(formData);
        let portalId = '143426573';
        let formGUID = '12c9e455-01ba-48d5-bf99-270c12eb4d57';
        const formApiUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGUID}`;
        try {
            const response = await axios.post(formApiUrl, {
                submittedAt: Date.now(),
                fields: Object.keys(formData).map(name => ({
                    name,
                    value: formData[name],
                })),
                context: {
                    // hutk: ':hutk',
                    pageUri: window.location.href,
                    pageName: document.title,
                },
                legalConsentOptions: {
                    consent: {
                        consentToProcess: true,
                        text: 'I agree to allow Example Company to store and process my personal data.',
                        communications: [
                            {
                                value: true,
                                subscriptionTypeId: 999,
                                text: 'I agree to receive marketing communications from Example Company.',
                            },
                        ],
                    },
                },
            });
            setLoading(false);
            if (response.status === 200) {
                toast.success('Your request has been submitted, we will get back to you ASAP');
            } else {
                toast.error(`Error: ${response.status}, ${response.data}`);
            }
        } catch (error) {
            setLoading(false);
            toast.error('An error occurred: ' + error);
        }
        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            message: '',
            country: '',
        });
    };
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <Layout>
            <div className={styles.body}>
                <div className={styles.formBody}>
                    <h1 className={styles.heading}>Get in touch</h1>
                    <h5 className={styles.subHeading}>Contact our Sales Team. Let us help you succeed.</h5>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-2">
                            <label className={styles.label}>
                                First Name <span>*</span>
                            </label>
                            <input
                                type="text"
                                className={'form-control ' + styles.customInput}
                                name="firstName"
                                value={formData?.firstName}
                                onChange={handleChangeFields}
                                required
                            />
                        </div>
                        <div className="mb-2">
                            <label className={styles.label}>
                                Last Name <span>*</span>
                            </label>
                            <input type="text" className={'form-control ' + styles.customInput} name="lastName" value={formData?.lastName} onChange={handleChangeFields} required />
                        </div>
                        <div className="mb-2">
                            <label className={styles.label}>
                                Work Email <span>*</span>
                            </label>
                            <input
                                type="email"
                                className={'form-control ' + styles.customInput}
                                // placeholder="name@company.com"
                                name="email"
                                value={formData?.email}
                                onChange={handleChangeFields}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className={styles.label}>
                                Phone Number <span>*</span>
                            </label>
                            <div className="row">
                                <div className="col-sm-12 col-md-3">
                                    <select
                                        onChange={handleChangeCountry}
                                        value={formData.country}
                                        required
                                        className={'form-control form-select ' + styles.customInput}
                                        placeholder="Country">
                                        <option value=""> Country</option>
                                        {CountryCode?.map(item => (
                                            <option value={item?.dial_code}>{item?.name} </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="col-sm-12 col-md-9 ">
                                    {/* <div class="mb-2 visible-xs"></div> */}
                                    <input
                                        type="text"
                                        className={'form-control ' + styles.customInput}
                                        name="phone"
                                        value={formData?.phone}
                                        onChange={handleChangeFields}
                                        required
                                        disabled={!formData?.country}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <label className={styles.label}>Message</label>
                            <textarea className={'form-control ' + styles.customInput} rows={6} name="message" value={formData?.message} onChange={handleChangeFields} />
                        </div>
                        <div>
                            <p className={styles.formSmall}>
                                Lens & Beyond M&A needs the contact information you provide to us to contact you about our products and services. You may unsubscribe from these
                                communications at any time. For information on how to unsubscribe, as well as our privacy practices and commitment to protecting your privacy,
                                please review our{' '}
                                <span
                                    className={styles.privacyPolicy}
                                    onClick={() => window.open('https://www.iubenda.com/privacy-policy/15531073/legal?ifr=true&height=650&newmarkup=yes&an=no', '_blank')}>
                                    Privacy Policy
                                </span>{' '}
                                .
                            </p>
                            <button type="submit" className={styles.submitBtn} disabled={loading}>
                                {loading ? <AiOutlineLoading3Quarters size={20} className="rotateLoader" /> : 'Submit'}
                            </button>
                        </div>
                    </form>
                </div>
                <div className={styles.marqueeBody}>
                    <MarqueeComponent />
                </div>
                <LastSection />
            </div>
        </Layout>
    );
};
export const LastSection = () => {
    const handleGetDemo = () => {
        window.open('https://meetings.hubspot.com/hutton130', '_blank');
    };
    return (
        <>
            <div className={styles.testimonial}>
                <span>Testimonial</span>
                <p>
                    When I was asked to prepare my business for investment I faced an empty dataroom and didn’t have the right materials ready. Thanks to Lens I was able to quickly
                    sort this out.
                </p>
                <div className={styles.testimonialByBody}>
                    <img src={personTwo} alt="person" />
                    <div>
                        <p>Colin Shove</p>
                        <p> Founder</p>
                        <p> Salon App</p>
                    </div>
                </div>
            </div>
            <div className={styles.sectionNine}>
                <div className={styles.sectionNineBlue}>
                    <div className={styles.blueSection}>
                        <h3 className="mb-0">Why wait for investor due diligence to uncover red flags?</h3>
                        <p>
                            Lens is the founders' ultimate exit preparation tool, covering everything from Strategy & Roadmap to Financials in one intuitive Virtual Data Room. Say
                            goodbye to fragmented processes and hello to efficient success with Lens.
                        </p>
                        <button onClick={handleGetDemo}>Get a Demo</button>
                    </div>
                    <img src={warnImg} alt="icons" className="position-absolute top-0 " />
                </div>
            </div>
        </>
    );
};

export default Contact;
