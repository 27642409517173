import React, { useEffect } from 'react';
import styles from './index.module.css';
import Logo from './assests/Logo.png';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { IoIosSettings } from 'react-icons/io';
const Header = () => {
    const [showMenu, setShowMenu] = React.useState(false);

    const handleGetDemo = () => {
        window.open('https://meetings.hubspot.com/hutton130', '_blank');
    };
    const navigate = useNavigate();

 
    useEffect(() => {
        window.Chargebee?.registerAgain();
    }, []);
    return (
        <>
            <nav className={styles.nav}>
                <div>
                    <img onClick={() => navigate('/')} src={Logo} alt="LOGO" height={48} width={121} />
                </div>
                <div className="d-flex align-items-center">
                    <span onClick={() => navigate('/resources')} className={styles.navItem}>
                        Resources
                    </span>
                    <span onClick={() => window.open('https://blog.lensapp.ai')} className={styles.navItem}>
                        Blog
                    </span>
                    <span onClick={() => navigate('/pricing')} className={styles.navItem}>
                        Pricing
                    </span>
                    <span className={styles.navItem} onClick={() => window.open('https://lens-dd.ai/', '_blank')}>
                        Sign in
                    </span>
                    <span onClick={handleGetDemo} className={styles.navItem} style={{ color: 'var(--secondary-alpha)' }}>
                        Get a Demo
                    </span>
                    <div class={styles.menuContainer}>
                        <div class={styles.iconContainer}>
                            <IoIosSettings size={20} className="me-1" />
                        </div>
                        <div class={styles.dropdownMenu}>
                            <button data-cb-type="portal">Manage account</button>
                        </div>
                    </div>
                    <AiOutlineMenu onClick={() => setShowMenu(true)} className={styles.menuBtn} />
                </div>
            </nav>
            {showMenu && (
                <div className={styles.menuBody}>
                    <div className="text-end">
                        <AiOutlineClose onClick={() => setShowMenu(false)} color="var(--White)" />
                    </div>
                    <p onClick={() => navigate('/resources')} className={styles.menuItem}>
                        Resources
                    </p>
                    <p onClick={() => window.open('https://blog.lensapp.ai')} className={styles.menuItem}>
                        Blog
                    </p>
                    <p onClick={() => navigate('/pricing')} className={styles.menuItem}>
                        Pricing
                    </p>
                    <p className={styles.menuItem} onClick={() => window.open('https://lens-dd.ai/', '_blank')}>
                        Sign in
                    </p>
                    <p className={styles.menuItem} style={{ borderBottom: 'none' }}>
                        <button onClick={handleGetDemo} className={styles.demoBtn}>
                            Get a Demo
                        </button>
                    </p>
                </div>
            )}
        </>
    );
};

export default Header;
